import React, { useEffect } from 'react'
import './NewsTemplate.css'
import adImage01 from '../../images/insta-ad.png'
import pressNews from '../../data/pressReleaseNews';
import { FiArrowLeft } from "react-icons/fi";

const PressReleaseMainContent = ({pageContext}) => {

    const createSlugFromTitle = (title) => {
        return title
          .toLowerCase()
          .replace(/[^\w\s./-]/g, '')  // Allow dots, slashes, and hyphens
          .replace(/\//g, '-')         // Replace slashes with hyphens
          .trim()
          .replace(/\s+/g, '-')        // Replace spaces with hyphens
          .replace(/-+/g, '-');        // Remove duplicate hyphens
      };

    const { slug } = pageContext;
    const news = pressNews.find((news) => 
        createSlugFromTitle(news?.title) == slug
    );

    //For news
    const splitContentWithATag = (content) => {
        const regex = /(.*?)(<a.*?>.*?<\/a>)(.*)/;
        const matches = content.match(regex);
        if (matches) {
          return [matches[1], matches[2], matches[3]];
        }
        return [content];
      };
    
    const extractUrlAndText = (content) => {
        const regex = /<a href='(.*?)'>(.*?)<\/a>/;
        const matches = content?.match(regex);
        if (matches) {
            return [matches[1], matches[2]];
        }
        return [null, null];
    };

    const renderUl = (content) =>{
        return(
          <ul>
            {Object.entries(content).map(([key, value]) => (
              <li key={key}>{value}</li>
            ))}
          </ul>
        )
      }

  return (
    <div>
      <div className='header-bacjground'></div>
      <div className='template-main-content-container'>
          <div className='template-main-content-container-left'>
              <div className="template-main-left-top">
                  <h3>Latest News</h3>
                  <div className="template-main-left-news-container">
                      {pressNews.reverse().slice(0,3).map((news)=>(
                          <div className="template-main-left-news" >
                              <div className='template-main-left-news-image'>
                                  <img src={news ? require(`../../images/${news.imageURL}`).default : null} alt="" />
                              </div>
                              <div className='template-main-left-news-content'>
                                  <small>{news.date +' '+ news.month}</small>
                                  <p>{news.title.length > 34 ? news.title.charAt(34) == ' ' ? news.title.substring(0, 34) + '...': news.title.substring(0, 35) + '...': news.title}</p>
                              </div>
                          </div>
                      ))}
                  </div>
              </div>
              <div className="template-main-left-bottom">
                  <h3>Instagram</h3>
                  <div className='template-main-left-bottom-image'>
                      <img src={adImage01} alt="" />
                  </div>
              </div>
          </div>


          {/* Right Section */}
          <div className='template-main-content-container-right'>
              {/* <a className='go-back-btn' href='/press-release'><FiArrowLeft size={22}/> Go back</a> */}
              <div className="template-main-container-right-news">
                  <div className="template-main-container-right-news-image">
                      <img src={news ? require(`../../images/${news.imageURL}`).default : null} alt="" />
                      <div className='template-main-container-right-news-image-date'>
                          <p>{news?.date}<br />{news?.month}</p>
                      </div>
                  </div>
                  <div className="template-main-container-right-news-content">
                      <small className='template-main-container-right-news-content-small'>Press Release</small>
                      <h2>{news?.title}</h2>
                      {news ?  
                        Object.entries(news).map(([key, value]) => {
                          const [part1, part2, part3] = key.includes('body-p-with-a') ? splitContentWithATag(value) : [value];
                          const [url, linkText] = extractUrlAndText(part2);
                          return(
                              <>
                              {key.includes('body-p-b')? (
                                  <p style={{fontWeight:'bold'}}>{value}</p>
                                ) : key.includes('body-p-i') ? (
                                  <><i>{value}</i> <br /></>) :
                                      key.includes('body-p-with-a') ? (
                                      <p>
                                        {part1}
                                        <a href={url} target="_blank">{linkText}</a>
                                        {part3}
                                      </p>
                                    ): key.includes('body-ul') ? (
                                      renderUl(value)
                                    ):
                                      (
                                      key.includes('body-p')?<p>{value}</p>: null
                                      )
                                  }
                            </>
                          )}):(
                            <p>No news content available.</p>
                        )
                      }
                  </div>
                  
              </div>
          </div>
      </div>
    </div>
  )
}

export default PressReleaseMainContent;